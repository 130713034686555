<template>
  <Loader style="flex-grow: 1">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ExtractString } from '@/utils'
// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  computed: {
    ...mapState('Project', ['item']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return {}
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    let metaInfo = ExtractString(
      this.$path('item.customFields.contact-page.content'),
      '<h2>(.*)<\/h2>',
    )

    return {
      title: metaInfo || 'Kontakt megler',
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  @keyframes PageTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__layout {
    @include respond-below('tablet') {
      grid-template-columns: 1fr !important;
    }
  }

  &__logo {
    @include respond-below('tablet') {
      padding-block: 3.125rem 0rem !important;
    }
  }

  &__text {
    color: var(--text-color);

    margin: 0 auto;
    text-align: center;

    margin-bottom: 2.875rem;

    @include respond-below('phone') {
      padding-inline: 1rem;
    }
    line-height: 1.5;

    h2 {
      @include header-font(2rem);
      margin-bottom: 1rem;
      font-weight: 500;
    }
    p {
      margin-block-start: 0em;
      margin-block-end: 0em;
    }
  }

  &__logo {
    margin: 0 auto;
    width: 200px;
    .kpb-image__image {
      object-fit: contain;
      background-color: inherit;
    }
    @include respond-below('phone') {
      width: 50%;
    }
  }

  &__salesman {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: var(--black);

    gap: 1.875rem !important;

    .kpb-contact-list {
      &__avatar {
        border: 2px solid var(--black);
        border-radius: 50%;
        opacity: 0;
        animation: PageTransition 0.4s 0.3s forwards;

        object-fit: cover;

        background: no-repeat 50% 50%;
        margin-bottom: 1.25rem;

        --size: 200px;

        width: var(--size);
        height: var(--size);

        max-width: 100%;
        max-height: 100%;

        @include respond-below('tablet') {
          // 66.666%
          --size: 360px;
        }
        @include respond-below('phone') {
          --size: 360px;
        }
      }

      &__item {
        gap: 0;

        align-items: center;

        // max-width: 30rem;
        // margin: 0 auto;
      }

      &__content {
      }

      &__name {
        font-size: 1rem !important;
        font-weight: 600 !important;
      }

      &__user-title {
      }

      &__phone,
      &__email {
        justify-content: center;

        margin-top: 0.2rem;
        &:hover {
          text-decoration: underline;
        }
      }

      &__contact-info {
        margin-top: 0rem !important;
        &-icon {
          display: none;
        }
      }

      &__phone {
      }

      &__email {
      }
    }
  }

  &__lead {
    @include respond-below('tablet') {
      max-width: 430px;
      margin: 0 auto;
    }

    input::placeholder {
      color: var(--form-placeholder);
    }

    .elder-input {
      color: var(--form);
      &__label {
        display: none;
      }
      &__value {
        input {
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &:focus-visible {
            outline: 0px !important;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }
        }
      }
      &__field {
        border-radius: 0px;
        border: 2px solid var(--grey);
        background-color: var(--beige);
        color: var(--form-placeholder);
      }
      &__element {
        font-size: 1rem;
        padding: 0;
        padding: 10px 15px;
      }
      &__validation {
        display: initial;
        align-items: initial;
        text-align: initial;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .elder-checkbox {
      &--checked {
        .elder-checkbox__box {
          svg {
            path {
              fill: white;
            }
          }
          &:before {
            background-color: var(--red);
          }
        }
      }

      &__box {
        border-color: var(--black);
        color: inherit;
        &:hover {
          background-color: var(--gray);
        }
      }
      &__label {
        a {
          word-break: break-word;
        }
      }
    }

    .elder-button {
      background-color: var(--primary);
      color: var(--black);
      border-radius: 0px;
      border: none;
      font-weight: 400;
      padding: 0.625rem 1.875rem;

      font-size: 1.25rem !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        color: var(--black);

        background-color: var(--primary-hover);
      }
    }
    .lead__form {
      background-color: inherit;

      &-actions {
        margin-top: 1rem;
        justify-content: start;
        .elder-botton {
          text-align: center;
        }

        .elder-button__icon {
          display: none;
        }
        .elder-button__label {
          padding: 0;
          text-align: center;
          justify-content: center;
          align-self: center;
        }
      }
    }
  }
}
</style>
