import { filter } from '@/utils'

export default function() {
  return [
    {
      component: 'Section',
      props: {
        class: 'contact-page',
        width: 'small',
        style: 'padding-block: 6.25rem 2.5rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            condition: () => {
              if (!this.$path('item.roles.salesman')) return false
              return this.$path('item.roles.salesman').length
            },
            data: {
              content: `<h2>KONTAKT MEGLER</h2><p>Har du noen spørsmål rundt arealer, området eller leie?<br>Fyll inn skjema, så kontakter jeg deg raskt!</p>`,
            },
            props: {
              class: 'contact-page__text',
            },
          },
          {
            component: 'Grid',
            props: {
              class: 'contact-page__layout',
              gap: '2.813rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'ContactList',
                  condition: () => {
                    if (!this.$path('item.roles.salesman')) return false
                    return this.$path('item.roles.salesman').length
                  },
                  data: {
                    contacts: this.$path('item.roles.salesman'),
                  },
                  props: {
                    class: 'contact-page__salesman',
                    showLogo: false,
                    contactDetailStyle: 'icon',
                  },
                },
                {
                  component: 'Lead',
                  data: {
                    project: this.$path('item.id'),
                    reference: { onModel: 'Project', ref: this.$path('item.id') },
                    fields: ['!contact.upsell', '!comment', '!title'],
                    placeholders: {
                      'contact.name': 'Navn...',
                      'contact.email': 'Epost-adresse...',
                      'contact.phone': 'Telefon...',
                      comment: '',
                    },
                    actionLabel: 'Send til megler',
                  },
                  props: {
                    class: 'contact-page__lead',
                  },
                },
              ].filter(f => filter(f)),
            },
          },
        ].filter(f => filter(f)),
      },
    },
  ]
}
